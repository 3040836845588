* {
	margin: 0;
	padding: 0;
}

body {
	margin: 0;
	font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	background-color: #070e13;
}
