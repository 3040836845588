h2 {
	width: 100%;
	font-size: 16px;
	margin-bottom: 2px;
	/* color: #b2061c; */
	background-color: #b2061c;
	padding: 8px 0;
}
.table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
	padding: 12px;
	box-sizing: border-box;
	color: rgb(255, 255, 255, 0.9);
	padding-bottom: 78px;
}

.table__box,
.table__box_cup {
	width: 100%;
	max-width: 360px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	padding: 15px;
	box-sizing: border-box;
	border: 0.1px solid rgb(255, 255, 255, 0.5);
}

.table__item {
	width: 100%;
	height: 16px;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 5fr 1fr 1fr 1fr 1fr 2fr 2fr;
	font-family: Arial, sans-serif;
	font-size: 14px;
	padding-bottom: 4px;
	border-bottom: 0.1px solid rgb(255, 255, 255, 0.4);
}

.table__item_akron {
	width: 100%;
	height: 16px;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 5fr 1fr 1fr 1fr 1fr 2fr 2fr;
	font-family: Arial, sans-serif;
	font-size: 14px;
	/* color: #b2061c; */
	background-color: #b2061c;
	padding: 4px 0;
	border-bottom: 0.1px solid rgb(255, 255, 255, 0.4);
}

.table__team {
	display: flex;
}

/* Кубок РФ */

.table__item_cup {
	width: 100%;
	height: 16px;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 5fr 1fr 1fr 1fr 1fr 1fr 2fr 2fr;
	font-family: Arial, sans-serif;
	font-size: 14px;
	padding-bottom: 4px;
	border-bottom: 0.1px solid rgb(255, 255, 255, 0.4);
}

.table__item_akroncup {
	width: 100%;
	height: 16px;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr 5fr 1fr 1fr 1fr 1fr 1fr 2fr 2fr;
	font-family: Arial, sans-serif;
	font-size: 14px;
	/* color: #b2061c; */
	background-color: #b2061c;
	padding: 4px 0;
	border-bottom: 0.1px solid rgb(255, 255, 255, 0.4);
}

.table__team_cup {
	display: flex;
}
