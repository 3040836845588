/* Основные стили для всего календаря */
.calendar {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
	padding: 12px;
	box-sizing: border-box;
	padding-bottom: 78px;
}

/* Контейнер для элементов календаря */
.calendar__box {
	background-color: #070e13; /* Белый фон для блока */
	border: 0.1px solid rgb(255, 255, 255, 0.5);
	/* border-radius: 10px; */
	/* box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1); */
	width: 100%;
	max-width: 360px; /* Максимальная ширина для мобильных экранов */
	padding: 15px;
	box-sizing: border-box;
}

.calendar__box_cup {
	background-color: rgb(178, 6, 28, 0.2); /* Белый фон для блока */
	border: 0.1px solid rgb(255, 255, 255, 0.5);
	/* border-radius: 10px; */
	/* box-shadow: 0 2px 5px rgba(255, 255, 255, 0.1); */
	width: 100%;
	max-width: 360px; /* Максимальная ширина для мобильных экранов */
	padding: 15px;
	box-sizing: border-box;
}

/* Элемент календаря */
.calendar__item {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	align-items: center;
	margin-bottom: 10px;
	font-family: Arial, sans-serif;
	font-size: 20px;
}

/* Команды */
.calendar__team_h,
.calendar__team_g {
	text-align: center;
	color: rgb(255, 255, 255, 0.9);
}

/* Разделитель */
.calendar__delimiter {
	color: #b2061c; /* Цвет разделителя, в стиле Telegram */
	font-weight: bold;
	font-size: 20px;
}

/* Подзаголовок */
.calendar__subitem {
	display: grid;
	grid-template-columns: 1fr 1fr 3fr 2fr;
	gap: 12px;
	margin-bottom: 12px;
	font-size: 14px;
	color: rgb(255, 255, 255, 0.9); /* Цвет подзаголовка */
}

/* Лига, тур, дата */
.calendar__liga,
.calendar__tour,
.calendar__date {
	text-align: center;
}

/* Зрители */
.calendar__spectators {
	text-align: center;
	font-size: 14px;
	color: #b2061c; /* Цвет для текстов типа "зрители", в стиле FC Akron */
}

.calendar__ticket {
	background-color: #b2061c;
	width: 100%;
	height: auto;
}

.calendar__ticket a {
	width: 100%;
	display: block;
	padding: 8px 0;
	/* text-transform: uppercase; */
	text-decoration: none;
	text-align: center;
	font-size: 14px;
	color: #fff; /* Цвет для текстов типа "зрители", в стиле FC Akron */
}
